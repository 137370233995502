import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { LoadingBox } from "./components";

export default function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="grid place-items-center h-screen">
            <LoadingBox />
          </div>
        }
      >
        <Router />
      </Suspense>
      <div className="relative">
        <Toaster
          containerStyle={{ zIndex: 9999 }}
          toastOptions={{
            position: "top-right",
            success: {
              style: {
                background: "#22c55e",
                color: "white",
              },
              iconTheme: {
                primary: "white",
                secondary: "#22c55e",
              },
            },
            error: {
              style: {
                background: "#ef4444",
                color: "white",
              },
              iconTheme: {
                primary: "white",
                secondary: "#ef4444",
              },
            },
          }}
          gutter={15}
        />
      </div>
    </BrowserRouter>
  );
}
