import { twMerge as cn } from "tailwind-merge";
import { EncryptStorage } from "encrypt-storage";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";

// secret
const encryptStorage = new EncryptStorage(import.meta.env.VITE_STORAGE, {
  storageType: "localStorage",
  encAlgorithm: "Rabbit",
});

const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

const isEmpty = (params) => {
  return [null, "", " ", "null", undefined].includes(params);
};

const pageNumber = (page, limit) => {
  return parseInt((page - 1) * limit);
};

const regexThousandSeparator = (number) => {
  if (number?.split(".")?.length > 2) {
    number = number?.replace(/\.+$/, "");
  }
  var regex = /^[0-9]*\.?[0-9]*$/;
  let val = number.match(regex);
  return val || null;
};

const formatNumber = (value) => {
  if (!value) return "";
  const parts = value.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas to the integer part
  return parts.length > 1 ? parts.join(".") : parts[0]; // Keep the decimal part
};

const parseNumber = (formattedValue) => {
  // Remove invalid characters, allow only one dot
  const numeric = formattedValue.replace(/[^0-9.]/g, ""); // Keep digits and dot
  const [integer, ...decimals] = numeric.split(".");
  return decimals.length > 0
    ? `${integer}.${decimals.join("")}` // Reconstruct number if more than one dot exists
    : numeric;
};

const validateTime = (value) => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Matches HH:mm format
  return timeRegex.test(value) || "Invalid time format. Use HH:mm.";
};

const formatHourAndMinutes = (value) => {
  const times = value?.split(":")?.splice(0, 2)?.join(":");
  return times;
};

const formatTimeInput = (value) => {
  // Remove non-numeric characters
  const numericValue = value.replace(/[^0-9]/g, "");

  const trimmedValue =
    numericValue.length > 4 ? numericValue.slice(0, 4) : numericValue;

  // Automatically insert a colon after the hour part
  if (trimmedValue.length > 2) {
    return `${trimmedValue.slice(0, 2)}:${trimmedValue.slice(2)}`;
  }

  return trimmedValue; // Return as is for less than 2 characters
};

const API = {
  sso: import.meta.env.VITE_URL_SSO,
  presensi: import.meta.env.VITE_URL_PRESENSI,
  presensiFile: import.meta.env.VITE_URL_PRESENSI_FILE,
  staff: import.meta.env.VITE_URL_STAFF,
};

function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    const context = this;

    // Clear the previous timeout if the function is called again
    clearTimeout(timeoutId);

    // Set a new timeout
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

function formatDateTime({ date, displayFormat = "DD-MM-YYYY", language = false }) {
  return dayjs(date).format(displayFormat);
}

function encryptData(value) {
  const key = import.meta.env.VITE_STORAGE;
  const encrypted = CryptoJS.AES.encrypt(value, key).toString();
  return encrypted;
}

function decryptData(value) {
  const key = import.meta.env.VITE_STORAGE;
  const decrypted = CryptoJS.AES.decrypt(value, key).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
}

const mongoObjectId = () => {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export {
  cn,
  API,
  encryptStorage,
  numberWithCommas,
  regexThousandSeparator,
  formatNumber,
  parseNumber,
  isEmpty,
  pageNumber,
  validateTime,
  formatTimeInput,
  formatHourAndMinutes,
  formatDateTime,
  debounce,
  encryptData,
  decryptData,
  mongoObjectId,
};
