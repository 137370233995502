import { forwardRef } from "react";
import { cn } from "../helpers";

const Textarea = forwardRef(
  ({ id, label, className, disabled, required, errors, ...props }, ref) => {
    return (
      <div className="w-full relative">
        <textarea
          ref={ref}
          name={id}
          autoComplete="off"
          id={id}
          disabled={disabled}
          required
          placeholder=""
          className={cn(
            `peer w-full p-2 pl-3 min-h-[100px] outline-none bg-white dark:bg-transparent border rounded-md transition disabled:opacity-70 disabled:cursor-not-allowed dark:text-white text-zinc-900
      `,
            errors?.[id]
              ? "border-rose-500 focus:border-rose-500"
              : "border-zinc-300 dark:border-gray-400 focus:border-[var(--color-primary)] valid:border-[var(--color-primary)]",
            className
          )}
          {...props}
        />
        <label
          htmlFor={id}
          className={cn(
            `absolute bg-white flex items-center gap-1 justify-center dark:bg-transparent px-1 cursor-text text-sm duration-150 transform -top-[0.625rem] z-10 origin-[0] left-3 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-[12px] peer-focus:-top-[0.625rem] peer-focus:bg-white dark:peer-focus:bg-[var(--bg-secondary)] peer-focus:px-1 dark:peer-valid:bg-[var(--bg-secondary)]
        `,
            errors?.[id]
              ? "text-rose-500"
              : "text-zinc-700 dark:text-white peer-focus:text-[var(--color-primary)] peer-valid:text-[var(--color-primary)]"
          )}
        >
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {errors?.[id] && (
          <p className="text-rose-500 text-sm mt-2">
            {errors?.[id]?.message?.toString()}
          </p>
        )}
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
