import React, { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { cn, encryptStorage } from "../helpers";
dayjs.extend(weekday);

export default function DatePicker({
  name,
  label,
  className,
  required,
  showShortcuts = false,
  values,
  useRange = true,
  single = false,
  onChange,
  displayFormat = "DD/MM/YYYY",
  errors,
  errorCustomId = "",
  placeholder = " ",
  disabled,
  minDate = null,
  maxDate = null,
  popoverDirection = "",
  primaryColor = "blue",
  separator = "~",
  filter = false,
}) {
  const lastYear = new Date(new Date().getFullYear() - 1, 0, 1);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  useEffect(() => {
    setValue(values);
  }, [setValue, values]);
  return (
    <>
      <div className="input-group-date relative">
        <Datepicker
          i18n="id"
          popoverDirection={popoverDirection}
          inputId={name}
          minDate={minDate}
          maxDate={maxDate}
          placeholder={placeholder}
          useRange={useRange}
          showShortcuts={showShortcuts}
          configs={{
            shortcuts: {
              today: {
                text: "Hari ini",
                period: {
                  start: dayjs().format("YYYY-MM-DD"),
                  end: dayjs().format("YYYY-MM-DD"),
                },
              },
              yesterday: "Kemarin",
              thisWeek: {
                text: "Minggu ini",
                period: {
                  start: dayjs().startOf("week").format("YYYY-MM-DD"),
                  end: dayjs().endOf("week").format("YYYY-MM-DD"),
                },
              },
              lastWeek: {
                text: "Minggu lalu",
                period: {
                  start: dayjs()
                    .startOf("week")
                    .weekday(-7)
                    .format("YYYY-MM-DD"),
                  end: dayjs().startOf("week").weekday(-1).format("YYYY-MM-DD"),
                },
              },
              past: (period) => `${period} hari terakhir`,
              currentMonth: "Bulan ini",
              pastMonth: "Bulan lalu",
              thisYear: {
                text: "Tahun ini",
                period: {
                  start: new Date(new Date().getFullYear(), 0, 1),
                  end: new Date(new Date().getFullYear(), 11, 31),
                },
              },
              lastYear: {
                text: "Tahun lalu",
                period: {
                  start: new Date(lastYear.getFullYear(), 0, 1),
                  end: new Date(lastYear.getFullYear(), 11, 31),
                },
              },
            },
          }}
          separator={separator}
          primaryColor={primaryColor}
          containerClassName={twMerge("datepicker", filter && "filter")}
          inputClassName={twMerge(
            "outline-none input-date",
            values?.startDate ? "has-value" : "",
            errors?.[name] || errorCustomId
              ? "border-[1.5px] border-red-500"
              : "border-[1.5px] border-gray-300",
            disabled ? "bg-gray-200" : "bg-transparent",
            disabled &&
              ![null, "", " ", "undefined"].includes(values?.startDate) &&
              "disabled-value",
            className
          )}
          disabled={disabled}
          value={value}
          displayFormat={displayFormat}
          onChange={onChange}
          startFrom={values?.startDate}
          asSingle={single}
        />
        <label
          className={twMerge(
            `input-date-label`,
            errors?.[name] || errorCustomId ? "text-rose-500" : "text-zinc-700"
          )}
          htmlFor={name}
        >
          {label || ""}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      </div>
      {(errors?.[name] || errorCustomId) && (
        <p
          className={cn(
            "text-rose-500 text-sm",
            errorCustomId && "mt-2",
            errors?.[name] && "mt-[-0.75rem]"
          )}
        >
          {errors?.[name]?.startDate
            ? errors?.[name]?.startDate?.message
            : errors?.[name]?.message || ""}
          {errorCustomId?.message?.toString()}
        </p>
      )}
    </>
  );
}
